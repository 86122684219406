@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/mixins.scss";

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500p;
  line-height: 16px;
  width: 100%;
  border-radius: 0;
}

.confirmModalTitleWrapper {
  display: flex;
  justify-content: center;

  .confirmModalTitle {
    color: $primary900;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 6px 0 16px;

    @include for-tablet-landscape-up {
      margin: 0 0 16px;
      max-width: 285px;
    }
  }
}
.confirmButtons {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;

  @include for-tablet-landscape-up {
    flex-direction: row;
    margin-top: 24px;
  }
}

.confirmButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;

  &:hover {
    background: $secondary500;
  }

  @include for-tablet-landscape-up {
    width: 50%;
  }
}

.cancelButton {
  @extend .buttonCommonStyles;
  background: $grey150;
  color: $grey800;

  &:hover {
    background: $grey150;
  }

  @include for-tablet-landscape-up {
    width: 50%;
  }
}
