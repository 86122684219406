@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.logListItem {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &.isFromRight {
    justify-content: end;
  }
}

