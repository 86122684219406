@import "@/src/styles/variables.scss";
@import "@/src/styles/scss/theme.module.scss";

.listingCell {
  align-items: center;
  display: flex;
  gap: 12px;
}

.listingTitle {
  color: $primary900;
  font-size: 12px;
  line-height: 16px;
  text-wrap: pretty;
}

.listingImage,
.imagePlaceholder {
  width: 100px;
  min-width: 100px;
  height: 60px;
  object-fit: cover;
}

.imagePlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: $secondary100;
}

.noImageText {
  color: $grey800;
  font-size: 8px;
  line-height: 120%;
  white-space: break-spaces;
  max-width: 80%;
}

@media (max-width: $breakpointMD) {
  .listingCell {
    gap: 8px;
    margin: 16px 0 0;
  }
  .listingTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}
