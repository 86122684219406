.debug {
  padding: 24px;
}

.debugOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  width: 500px;
  max-width: 100%;
}

.debugOptionRow {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}

.debugOptionRowFlexible {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.iconBackground {
  background-color: #008800;
}
