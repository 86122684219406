@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.noCreatedIquiriesWrapper {
  position: relative;
  min-height: 80vh;
}

:global .MuiBackdrop-root {
  top: 80px;
}

.noInquiryDialog {
  padding: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  top: 80px;

  .iconWrapper {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }

  .noInquiryTitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 0 35px;
    color: $primaryDark;
    max-width: 400px;
  }

  .noInquiryText {
    font-size: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $grey800;
    margin-bottom: 20px;
    max-width: 400px;
  }

  .noInquiryDialogButton {
    color: $paper;
    background-color: $secondaryMain;
    margin: 0 20px 15px;
  }
}

.skeletonImage {
  object-fit: cover;
  width: 100%;
  height: calc(100vh - 120px);
}

@media (max-width: $breakpointMD) {
  .commonWrapperStyles {
    height: 80vh;
  }
  .noInquriesWrapper {
    padding: 80px 20px 0;
  }

  .noInquiryDialog {
    .iconWrapper {
      padding: 10px 0;
    }
  }
}

@media (min-width: $breakpointMD) {
  :global .MuiDialog-container {
    align-items: center!important;
  }
}
