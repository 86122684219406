@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.infoWrapper {
  background-color: $warning100;
  padding: 16px 16px 16px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.infoText {
  color: $warning700;
  text-align: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
