@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.pageLoadingLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 336px);
  height: 40%;
}

@media (max-width: $breakpointMD) {
  .pageLoadingLayout {
    height: 40%;
  }
}
