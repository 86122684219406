@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.loadingSpinnerWrapper {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

