@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.inputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.filtersInput {
  margin: 0 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: -2px;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 1px solid $grey750;
  padding-bottom: 6px;
  flex-grow: 2;

  &::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey750;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.filterButton, .sortButton {
  min-width: 16px;
  width: 16px;
  padding: 0;
  height: 16px;
}

.filterButton {
  position: relative;
  margin-right: 2px;

  .appliedFiltersAmount {
    position: absolute;
    right: -11px;
    top: -15px;
    z-index: 999;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $secondaryLight;
    color: $secondaryMain;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.sortButton {
  margin-left: 15px;
}

@media (max-width: 1200px) {
  .filtersInput {
    padding-right: 18px;
  }
}

@media (min-width: $breakpointMD) {
  .inputWrapper {
    margin-bottom: 10px;
    padding-left: 10px;
    margin-right: 10px;

    .inputFilterLabel {
      width: 10%;
    }

    .filtersInput {
      width: 70%;
    }

    .buttonWrapper {
      width: 20%;

      .sortButton {
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: $breakpointLG) {
  .inputWrapper {
    .inputFilterLabel {
      width: auto;
    }

    .filtersInput {
      width: auto;
      flex-grow: 2;
    }

    .buttonWrapper {
      width: auto;
    }
  }
}

