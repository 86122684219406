@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.detailsMapWrapper {
  width: 100%;

  .detailsMap {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: $breakpointMD) {
  .detailsMapWrapper {
    width: 40%;
    padding: 40px 0 30px 40px;
    height: 445px;
  }
}
