@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.photoWrapper {
  position: relative;
  height: 220px;
  display: flex;
  gap: 4px;

  &.selected {
    box-shadow: 0px 4px 20px 0px rgba(32, 92, 223, 0.2);
  }

  .uploadedPhoto {
    width: calc(100% - 28px);
    height: 220px;
    object-fit: cover;

    &.isLoading {
      filter: brightness(50%);
    }
  }

  .imageLoaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    color: $paper;
  }

  .coverImageTag {
    position: absolute;
    visibility: visible;
    right: 8px;
    top: 8px;
    padding: 4px 8px 4px 6px;
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    color: $success300;
    background-color: $success50;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .setCoverImageTag {
    @extend .coverImageTag;
    background: $warning100;
    color: $warning700;
    visibility: hidden;
    cursor: pointer;
  }

  .trashIconContainer {
    visibility: hidden;
    position: absolute;
    cursor: pointer;
    bottom: 8px;
    right: 8px;
    height: 24px;
    width: 24px;
  }

  .dotsHandle {
    cursor: grab;
    user-select: none;
    -webkit-user-select: none;

    .dotsHandleImage {
      pointer-events: none;
    }
  }

  .checkboxWrapper {
    position: absolute;
    top: 8px;
    left: 12px;
    visibility: hidden;

    input[type="checkbox"] {
      margin: 0;
      transform: scale(1.5);
    }
  }

  &:hover {
    .setCoverImageTag,
    .trashIconContainer {
      visibility: visible;
    }
  }

  &:active {
    .setCoverImageTag,
    .trashIconContainer {
      visibility: visible;
    }
  }
}

@media (min-width: $breakpointMD) {
  .photoWrapper {
    height: 160px;
    transition: 0.3s;

    &:hover {
      .checkboxWrapper {
        visibility: visible;
      }
    }

    .uploadedPhoto {
        width: 235px;
        height: 160px;
      }

    .checkboxWrapper.isSelected {
      visibility: visible;
    }

    &:active {
      .checkboxWrapper {
        visibility: visible;
      }
    }
  }
}
