@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.detailsForm {
  padding: 20px 0;

  .formSection {
    .formSectionTitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: $dark300;
      margin-bottom: 6px;
    }
  }

  .formItem {
    margin-bottom: 20px;
    position: relative;

    .inputLabel {
      font-size: 12px;
      line-height: 16px;
      color: $grey750;
      text-transform: uppercase;
      margin-bottom: 10px;

      &.required {
        &:after {
          content: "*";
          color: $error700;
          position: relative;
          top: 2px;
          left: 3px;
        }
      }
    }

    .checkboxLabel {
      color: $dark300;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .checkbox {
      color: $grey750;

      &:global(.Mui-checked) {
        color: $secondary500;
      }
    }

    .textField {
      & :global .MuiInputBase-root {
        background-color: $secondary100 !important;
      }

      input,
      textarea {
        background-color: $secondary100 !important;
        font-size: 12px !important;
        color: $grey800 !important;
      }

      p {
        margin-left: 0 !important;
      }

      &.listingDescription {
        p {
          padding-right: 40px;
        }
      }

      &.thingsYouShouldKnow {
        p {
          padding-right: 40px;
        }
      }
    }

    .textFieldAddressWrapper {
      position: relative;
    }

    .addressIcon {
      position: absolute;
      top: 17px;
      left: 11px;
    }

    .addressInputLoaderWrapper {
      position: absolute;
      top: 17px;
      right: 40px;
      padding: 0;
    }

    .textFieldAddress {
      width: 100%;
      border: 1px solid $grey400;
      background-color: $secondary100;
      font-size: 12px;
      color: $grey800;
      padding: 20px 20px 20px 40px;
      border-radius: 2px;

      &::placeholder {
        font-size: 16px;
        line-height: 24px;
        color: $grey400;
      }

      &:focus,
      &:hover {
        border-color: $grey900;
      }

      &:focus {
        border-width: 2px;
      }

      &.invalid {
        border-color: $error700;
      }
    }
  }

  .detailsSaveButton {
    color: $paper;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding: 12px 8px;
    background-color: $secondaryMain;
    width: 100%;

    &.isDisabled {
      background-color: rgba($secondaryMain, 0.2);
    }
  }
}

@media (min-width: $breakpointXS) {
  .detailsForm {
    .formItemsWrapper {
      display: flex;
      flex-direction: column;

      .formItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $breakpointMD) {
  .detailsForm {
    width: 60%;

    .formItemsWrapper {
      flex-direction: row;
      justify-content: space-around;
      margin: 0 -10px;

      .formItem {
        padding: 0 10px;
        width: calc(100% / 3);
      }
    }

    .detailsSaveButton {
      width: auto;
    }
  }
}
