@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.listingsContainer {
  padding: 40px;
  max-width: 1400px;
  margin: auto;
}

.pageHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  & .pageTitle {
    color: $secondary500;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    margin-left: -1px;
  }

  & .pageSubtitle {
    color: $grey800;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  & .addListingButton {
    color: $paper;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    background: $secondary500;
    padding: 12px 32px;
  }
}

.paginationWrapper {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  background-color: $paper;
  width: 100%;
  position: relative;
}

@media (max-width: $breakpointMD) {
  .listingsContainer {
    padding: 0;
  }

  .listingsWrapper {
    padding: 24px 16px 20px;
    background-color: $secondary100;
  }

  .paginationWrapper {
    padding: 0;
  }

  .pageHeaderContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    margin-bottom: 0;

    & .pageTitleContainer {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    & .pageTitle {
      color: $secondary500;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    & .addListingButton {
      width: 100%;
    }
  }
}

@media (min-width: 1480px) {
  .listingsContainer {
    padding: 0;
  }
}
