@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.select {
  &:global(.MuiAutocomplete-root) {
    background-color: $grey50;
    &:focus-visible {
      outline: none;
    }
  }
}

.customInput {
  & :global .MuiInputLabel-root {
    font-size: 12px;
    color: $grey600;
    margin-top: 2px;
    padding: 0 3px;
    z-index: 999;
    background-color: $secondary100;
  }

  & :global .MuiInputBase-root {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: $secondary100;
    padding: 8px 15px;

    &:focus-visible {
      outline: "none";
    }
  }

  & :global .MuiInputBase-input {
    background: inherit;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $grey800;
  }

  & :global .MuiSelect-select.MuiSelect-outlined {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      outline: none;
    }
  }
}
