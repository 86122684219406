@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.inquiryItem {
  transition: all 0.3s ease-out;

  cursor: pointer;
  padding: 24px 20px;
  border-bottom: 1px solid $grey300;
  background-color: $paper;
  border-right: 2px solid transparent;

  &.active {
    background-color: $grey200;
    border-right: 2px solid $secondaryMain;
  }

  &.needsUpdate {
    border-left: 2px solid $warning700;
  }

  .inquiryInfo {
    display: inline-block;

    .commonText {
      display: inline-block;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      color: $grey700;
    }

    .inquiryType {
      @extend .commonText;
      border-bottom: 1px solid $grey400;
    }

    .inquiryDescription {
      @extend .commonText;
    }
  }

  .eventNameWrapper {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 4px;
    }
  }

  .inquiryTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: $darkPaper;
    margin: 3px 0;
  }

  .dateTime {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey700;
    margin-bottom: 10px;
  }

  .cardBottomWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .buttonsWrapper {
      display: inherit;
      justify-content: inherit;
      gap: 8px;
    }
  }

  .amountDaysContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 4px;
  }

  .amountDaysPayment {
    font-size: 10px;
    line-height: 16px;
    color: $grey500;
    margin-top: -3px;
    padding-left: 20px;

    &.needsUpdate {
      font-weight: 500;
      color: $warning700;
    }
  }

  .lastUpdateDate {
    font-size: 10px;
    line-height: 16px;
    color: $grey500;
    margin-top: -3px;
    padding-left: 20px;

    &.needsUpdate {
      font-weight: 500;
      color: $primary900;
      border-radius: 100px;
      background: $warning300;
      padding: 4px 8px;
    }
  }
}
