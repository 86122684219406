@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.chatTabWrapper {
  background-color: $secondary100;
  height: calc(100vh - 260px);
  overflow-y: scroll;

  &.withClosedBanner {
     height: calc(100vh - 368px);
  }

  &.withNeedsUpdateBanner {
    height: calc(100vh - 400px);
  }
}

.inquiryMessagesList {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  list-style: none;
  margin: 0;
}

@media (max-width: $breakpointMD) {
  .inquiryMessagesList {
    padding: 20px;
  }

  .chatTabWrapper {
    height: unset;
    min-height: 100%;
  }

  .chatTabWrapper::-webkit-scrollbar {
    display: none !important;
  }
}

