@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.listingsList {
  padding: 0;

  .inquiryCard {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 8px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: $paper;
    box-shadow: 0px 20px 24px -4px rgba(69, 80, 100, 0.08),
      0px 8px 8px -4px rgba(69, 80, 100, 0.03);
  }
}

