@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.tagSection {
  padding: 20px;
  border: 1px solid $secondary300;
  width: 500px;
  background: rgba(245, 247, 249, 0.12);

  .sectionHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    
    .sectionTitle {
      color: $secondary500;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 0;
    }
  }
}
