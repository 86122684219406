@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.footerIframeWrapper {
  position: relative;
  width: 100%;
  background-color: $primaryDark;
  min-height: 480px;
}

@media (max-width: 1200px) {
  .footerIframeWrapper {
    min-height: 770px;
  }
}

@media (max-width: 840px) {
  .footerIframeWrapper {
    min-height: 865px;
  }
}
