@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.skeletonWrapper {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  inset: 0px;
  width: 100%;
  height: calc(100vh - 80px);

  .skeleton {
    position: absolute;
    inset: 0px;
    padding: 0px;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
