@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.uploadedPhotos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
  border-radius: 0;
}

.photosContent {
  width: 100%;
}

.errorSnackBar {
  background-color: $errorLight;
}

.uploadButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;
  margin-bottom: 20px;

  &.isDisabled {
    background-color: rgba($secondaryMain, 0.2);
  }

  &:hover {
    background-color: darken($secondary500, 5);
  }
}

.deleteButton {
  @extend .buttonCommonStyles;
  background: $secondary250;
  color: $secondary500;
  margin-bottom: 20px;
}

.clearButton {
  @extend .buttonCommonStyles;
  background: $grey150;
  color: $grey800;
  margin-bottom: 20px;
}

.hiddenInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: $breakpointMD) {
  .uploadButtonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .uploadButton,
  .deleteButton,
  .clearButton {
    width: unset;
  }

  .uploadedPhotos {
    flex-direction: row;
    gap: 40px;
  }
}
