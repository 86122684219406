@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.dropZone {
  display: flex;
  width: 100%;
  height: 220px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid $secondary50;
  background: $secondary100;
  font-size: 12px;
  line-height: 16px;

  &.activeDrop {
    background-color: $secondary150;
  }

  .uploadText {
    color: $dark300;
    text-align: center;
    font-size: inherit;
    font-weight: 500;
    line-height: inherit;
    width: 100px;
  }

  .uploadSupportedText {
    color: $grey800;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

@media (min-width: $breakpointMD) {
  .dropZone {
    width: 235px;
    height: 160px;
  }
}
