@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.modalTitleWrapper {
  display: flex;
  justify-content: center;
}

.modalTitle {
  color: $primary900;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 300px;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  gap: 10px;

  .option {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }

  .link {
    text-decoration: none;
    width: 100%;

    &.disabled {
      cursor: not-allowed;
    }

    @media (min-width: $breakpointMD) {
      width: 50%;
    }
  }

  .optionImage {
    height: 90px;
    object-fit: cover;
  }

  .optionButton {
    height: 48px;
    padding: 14px 12px;
    background: $secondary250;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    &.disabled {
      cursor: not-allowed;
    }
  }
}

@media (max-width: $breakpointMD) {
  .modalTitle {
    width: unset;
  }

  .optionsContainer {
    flex-direction: column;
    gap: 20px;

    .optionImage {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }
  }
}
