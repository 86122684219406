@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.messageWrapper {
  width: 100%;

  .inner {
    display: flex;
    gap: 12px;
    margin-right: 96px;
    margin-left: 0px;
    width: 100%;

    &.fromRight {
      flex-direction: row-reverse;
      margin-right: 0px;
      margin-left: 96px;
    }
  }

  &.fromRight {
    display: flex;
    justify-content: flex-end;
  }

  .imageWrapper {
    object-fit: cover;
    border-radius: 50%;
  }

  .userInitial {
    @extend .imageWrapper;

    width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: $secondaryLight;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .userInitialText {
    text-transform: uppercase;
    color: $grey800;
    font-weight: 400;
    font-feature-settings: "clig" off, "liga" off;
  }

  .messageBox {
    position: relative;
  }

  .message {
    display: flex;
    padding: 8px 12px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    min-width: 280px;
    max-width: 100%;
    width: fit-content;
    background-color: $secondary150;
    border-radius: 0 12px 12px 12px;
    position: relative;

    .confettiWrapper {
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
    }

    &.fromRight {
      border-radius: 12px 0px 12px 12px;
      background-color: $paper;
    }
  }

  .messageBox {
    width: auto;
  }

  .messageFullWidth {
    width: 100% !important;
  }

  .titleIcon {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 12px;

    &.submitIconWrapper {
      background-color: $success50;
    }
  }

  .commonTextStyles {
    font-size: 12px;
    line-height: 16px;
  }

  .messageTitle {
    @extend .commonTextStyles;
    
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .email {
    @extend .commonTextStyles;

    color: $grey400;
    letter-spacing: 0em;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .subject {
    @extend .commonTextStyles;

    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: $grey800;
    padding-right: 30px;
  }

  .showButton {
    @extend .commonTextStyles;

    font-weight: 500;
    letter-spacing: 0em;
    color: $secondary500;
    margin: 0 auto;
  }

  .messageContent {
    padding: 12px;
    width: 100%;
    background-color: $paper;

    &.withBorder {
      border-radius: 8px;
      border: 1px solid $grey150;
    }
  }

  .messageTime {
    color: $grey750;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    cursor: pointer;

    &.fromRight {
      text-align: right;
    }

    .fullUpdatedDate {
      display: none;
    }

    &:hover {
      .fullUpdatedDate {
        display: block;
      }
      .shortUpdatedDate {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpointMD) {
  .messageWrapper {
    width: 100%;

    .messageBox {
      flex-grow: 2;
    }

    .inner {
      width: unset;
      margin-right: unset;
      margin-left: unset;

      &.fromRight {
        flex-direction: row-reverse;
        margin-right: unset;
        margin-left: unset;
      }
    }

    .message {
      min-width: 100%;
    }
  }
}

@media (max-width: $breakpointSM) {
  .messageWrapper {
    .messageFullWidth {
      width: 80vw !important;
    }
  }
}

@media (max-width: $breakpointXS) {
  .messageWrapper {
    .messageFullWidth {
      width: 75vw !important;
    }
  }
}
