@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.cancelOption {
  margin: 0;
  & :global .MuiFormControlLabel-label {
    color: $dark300;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  & :global .MuiRadio-root {
    color: $grey750;
  }

  & :global .MuiRadio-root.Mui-checked {
    color: $secondary500;
  }

  & :global .MuiSvgIcon-root {
    font-size: 16px;
  }
}

.closeRequestButton {
  background-color: $error50;
  color: $error700;

  padding: 14px 10px;
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  transition: background-color 0.5s ease;
  &:disabled {
    color: $paper;
    background-color: $errorLight;
  }
}

.otherReasonField {
  margin-top: 16px;
  border: none;
  & :global .MuiInputBase-input {
    background-color: $secondary100;
    padding: 12px;
    color: $dark300;
    border-radius: 1px;
    border: 1px solid $secondary200;
    font-size: 12px;
    line-height: 16px;
    &::placeholder {
      color: $grey800;
      font-size: 12px;
    }
    &:focus-visible {
      border: 1px solid $secondary500;
      background: $paper;
    }
  }

  & :global .MuiFormHelperText-root {
    color: $success300;
    text-align: right;
    font-size: 12px;
    line-height: 16px;
  }

  & :global .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.otherReasonField.error {
  & :global .MuiInputBase-input {
    border: 1px solid $error700;
    &:focus-visible {
      border: 1px solid $error700;
    }
  }
  & :global .MuiFormHelperText-root {
    color: $error700;
  }
}
