@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.contactForm {
  padding: 20px 0;

  .formSection {
    .formSectionTitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: $dark300;
      margin-bottom: 6px;
    }
  }

  .formItemsWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .formItem {
    margin-bottom: 20px;
    position: relative;

    &.twoInRow {
      width: calc(100% / 2);
    }
    .inputLabel {
      font-size: 12px;
      line-height: 16px;
      color: $grey750;
      text-transform: uppercase;
      margin-bottom: 10px;

      &.required {
        &:after {
          content: "*";
          color: $error700;
          position: relative;
          top: 2px;
          left: 3px;
        }
      }
    }

    .textField {
      & :global .MuiInputBase-root {
        background-color: $secondary100 !important;
      }

      input,
      textarea {
        background-color: $secondary100 !important;
        font-size: 12px !important;
        color: $grey800 !important;
      }

      p {
        margin-left: 0 !important;
      }
    }
  }

}

@media (min-width: $breakpointMD) {
  .contactForm {
    padding: 0;
    .formItemsWrapper {
      flex-direction: row;
      justify-content: space-around;
      margin: 0 -10px;

      .formItem {
        padding: 0 10px;
        width: calc(100% / 3);
        &.twoInRow {
          width: calc(100% / 2);
        }
      }
    }
  }
}
