@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.commonButton {
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 5px;
  margin-right: 5px;
  text-transform: capitalize;
}

.due,
.overdue,
.processing {
  @extend .commonButton;
}

.processing {
  color: $grey800;
  background-color: $grey150;
}

.due {
  color: $secondary500;
  background-color: $secondaryLight;
}

.overdue {
  background-color: $error50;
  color: $error700;
}
