@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.contentSection {
  padding: 20px;
  margin: 0 -20px;
}

@media (min-width: $breakpointMD) {
  .contentSection {
    padding: 20px 40px;
    margin: 0;
    margin-right: -20px;
    display: flex;
  }
}
