@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.noMatchesWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 20px 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey700;
  }

  .description {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $grey600;
    text-align: center;
    margin-bottom: 20px;
  }

  .clearButton {
    background-color: $grey300;
    color: $grey600;
  }
}

.errorWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .errorIcon {
    margin-bottom: 20px;
  }

  .description {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 15px;
    text-align: center;
    color: $errorMain;
  }
}

