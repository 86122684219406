@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";
@import "@/src/styles/mixins.scss";

.vendorCard {
  padding: 25px 16px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  cursor: grab;
  border-radius: 12px;
  background: $paper;
  box-shadow:
    0px 20px 24px -4px rgba(69, 80, 100, 0.08),
    0px 8px 8px -4px rgba(69, 80, 100, 0.03);
  transition: box-shadow 0.5s;
  position: relative;

  &:hover {
    box-shadow: 0px 4px 20px 0px rgba(32, 92, 223, 0.2);
  }

  @include for-tablet-landscape-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    background: $secondary100;
    border-radius: 0px;
    box-shadow: none;
  }

  .vendorDetails {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .vendorImage {
    width: 100px;
    min-width: 100px;
    height: 60px;
    object-fit: cover;
  }

  .vendorDescriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .vendorTitle {
    color: $primary900;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .vendorDescription {
    overflow: hidden;
    height: 32px;
    color: $primary900;
    text-overflow: ellipsis;
    white-space: break-spaces;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .dotsHandle {
    cursor: grab;
    user-select: none;
    -webkit-user-select: none;
    left: 5px;
    position: absolute;
    top: 7px;

    .dotsHandleImage {
      pointer-events: none;
    }
  }

  .deleteVendorBtn {
    padding: 0;
    height: 40px;
    background-color: $secondary250;
    width: 100%;
    display: flex;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    @include for-tablet-landscape-up {
      min-width: 35px;
      height: 35px;
      background-color: transparent;
      gap: 0;
      transition: transform 0.5s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
