.mainLayoutBody {
  min-height: calc(100vh - 200px);
}

@media (max-width: 600px) {
  .mainLayoutBody {
    min-height: unset;
  }
}

.bannerSticky {
  position: sticky;
  top: 80px;
  z-index: 999;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.withoutShadow {
  box-shadow: none;
}
