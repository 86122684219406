@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.contentWrapper {
  max-height: 213px;
  overflow-y: scroll;
  width: inherit;
}

.shortEmailText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: $primaryDark;
}

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.6em;
}

.messageTitle {
  color: $primary900;
  text-align: center;
  padding: 6px 16px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.messageSubtitle {
  color: $grey800;
  text-align: center;
  padding: 0 16px 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}


.messageContentWrapper {
  height: 100%;
  overflow: auto;
}

.messageContent {
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  border: 1px solid $grey150;
  height: fit-content;
  overflow: hidden;
}

.attachments {
  padding: 0 16px;
}

.bodyHtml {
  max-height: 65vh;
  overflow: auto;
}

.dragDialog {
  & :global .MuiDialog-container {
    align-items: flex-end;
  }
  & :global .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    height: fit-content;
    min-height: 60vh;
    max-height: 90vh;
    margin: 0;
  }
}