@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

@media (max-width: $breakpointMD) {
  .titleWrapper {
    padding: 0 0 15px;
    border-bottom: 1px solid $grey300;

    .filterTitle {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
  }

  .filtersWrapper {
    padding: 0 20px;
    overflow-y: scroll;
  }
}

