@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.commonTextStyle {
  font-size: 12px;
  line-height: 16px;
}

.backButton {
  @extend .commonTextStyle;

  font-weight: 500;
  color: $grey800;
  padding: 0;
  margin: 25px 0 20px;

  &:hover {
    background-color: $paper;
  }
}

.backButtonIcon {
  margin-right: 2px;
}
