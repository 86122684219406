@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.baseModal {
  .baseModalContent {
    padding: 24px 32px;
    min-width: 420px;
    max-width: 490px;
    background-color: $paper;
    max-height: 90vh;
    overflow: hidden;
    height: fit-content;
  }
}

.dialogWithExitBtnContainer {
  position: relative;
  z-index: 1;
}

.dialogWithExitExitBtnInner {
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--common-black);
  z-index: 999;
}

.dragBarContainer {
  margin-bottom: 10px;
}

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500p;
  line-height: 16px;
  width: 100%;
  border-radius: 0;
}

.modalTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modalTitle {
    color: $primary900;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 6px 0 4px;
  }

  .modalSubtitle {
    color: $grey800;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;

    &.error {
      color: $error700;
    }

    .link {
      color: $secondary500;
      text-decoration: none;
    }
  }
}

.photoList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0 24px;
  overflow-y: auto;
  max-height: calc(90vh - 200px);

  .dropZone {
    width: 100px;
    height: 70px;
    font-size: 8px;
    line-height: 12px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.confirmButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;
  width: 100%;

  &:hover {
    background: $secondary500;
  }
}

@media (min-width: $breakpointMD) {
  .modalTitleWrapper {
    .modalTitle {
      margin: 0 0 4px;
      max-width: 285px;
    }
  }
}

@media (max-width: $breakpointMD) {
  .baseModal {
    .baseModalContent {
      max-width: 100%;
      min-width: unset;
      width: 100%;
      margin: 0px;
      padding: 20px;
    }

    & :global .MuiDialog-container {
      align-items: flex-end;
    }

    .photoList {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin: 8px 0 24px;

      .dropZone {
        width: calc(100% / 4 - 6px);
        height: 90px;
      }
    }
  }
}

@media (max-width: $breakpointSM) {
  .baseModal {
    .photoList {
      .dropZone {
        width: calc(100% / 3 - 6px);
        height: 70px;
      }
    }
  }
}
