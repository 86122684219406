@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.statusIcon {
  margin-right: 6px;
}

.commonLabel {
  padding: 4px 8px 4px 6px;
  border-radius: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.newStatusLabel {
  @extend .commonLabel;

  color: $secondary700;
  background-color: $grey250;
  width: 70px;
}

.pendingStatusLabel {
  @extend .commonLabel;

  color: $secondary500;
  background-color: $secondary250;
  width: 135px;
}

.draftStatusLabel {
  @extend .commonLabel;

  color: $warning700;
  background-color: $warning100;
  width: 70px;
}

.unpublishedStatusLabel {
  @extend .commonLabel;

  background-color: $grey150;
  color: $grey800;
  width: 115px;
}

.publishedStatusLabel {
  @extend .commonLabel;

  background-color: $success50;
  color: $success300;
  width: 100px;
}
