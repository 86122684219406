@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.bannerContainer {
  border-radius: 2px;
  background: $warning100;
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  padding: 16px;
}

.bannerTitleWrapper {
  display: flex;
  gap: 8px;
}

.bannerTitle {
  color: $dark300;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 4px;
}

.bannerReason {
  color: $grey800;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 16px;
}

.ctaWrapper {
  display: flex;
  gap: 16px;
}

.bannerCTA {
  border: 1px solid $grey800;
  background: $paper;
  padding: 14px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;

  &.contact {
    color: $secondary500;

    &:hover {
      color: $secondary400;
    }
  }
  
  &.book {
    color: $success300;

    &:hover {
      color: $successDark;
    }
  }

  &.close {
    color: $error700;

    &:hover {
      color: $errorLight;
    }
  }

  &:hover {
    background-color: $paper;
  }
}

@media (max-width: $breakpointMD) {
  .bannerContainer {
    padding: 16px 8px;
  }

  .ctaWrapper {
    gap: 4px;
  }

  .bannerCTA {
    padding: 8px;
  }
}
