@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.closeModal {
  & :global .MuiPaper-root {
    padding: 24px;
    min-width: 420px;
    max-width: 600px;
  }
}

.dialogWithExitBtnContainer {
  position: relative;
  z-index: 1;
}

.dialogWithExitExitBtnInner {
  position: absolute;
  top: -8px;
  right: -8px;
  color: $darkPaper;
  z-index: 999;
}

.modalTitle {
  color: $darkPaper;
  text-align: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.modalSubtitle {
  color: $grey800;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;

  &.error {
    color: $error700;
  }
}

.dragBarContainer {
  margin-bottom: 10px;
}

@media (max-width: $breakpointMD) {
  .cancelModal {
    & :global .MuiPaper-root {
      padding: 16px;
    }

    & :global .MuiDialog-container {
      align-items: flex-end;
    }
    & :global .MuiDialog-paper {
      max-width: 100%;
      min-width: unset;
      width: 100%;
      margin: 0px;
    }
  }
}
