@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.truncatedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uploadFile {
  display: flex;
  padding: 13px;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary100;
  margin: 5px 0 12px;
  width: 100%;

  .uploadMessage {
    @extend .truncatedText;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $grey800;
    width: 60%;
  }

  .uploadButton {
    display: flex;
    height: 40px;
    padding: 14px 10px;
    justify-content: center;
    align-items: center;
    background-color: $secondaryLight;
    color: $secondary500;
    transition: all 0.3s ease-in-out;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background-color: darken($secondaryLight, 1);
    }
  }
}

.loadingSpinnerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.hiddenFileInput {
  display: none;
}

.uploadFileInfo {
  padding: 13px;
  border: 1px solid $grey300;
  display: flex;
  align-items: center;
  margin: 5px 0 12px;
}

.uploadFileInfoFail {
  @extend .uploadFileInfo;

  border-color: $error700;
}

.fileData {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0 12px;
  width: 50%;
  flex-grow: 2;

  .fileName {
    @extend .truncatedText;

    color: $dark300;
    width: 90%;
  }

  .fileNameSuccess {
    @extend .fileName;
  }

  .fileNameFail {
    @extend .fileName;
  }

  .fileSize {
    color: $grey800;
  }
}

.failure {
  color: $error700;
  font-size: 12px;
}

.commonButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  min-width: 20px;
}

.checkButton,
.retryUpload {
  @extend .commonButton;

  margin-right: 8px;
}

.removeButton {
  @extend .commonButton;
}

.retryUpload {
  cursor: pointer;
}

@media (min-width: $breakpointXS) {
  .truncatedText {
    width: 100%;
  }

  .uploadFile {
    .uploadMessage {
      width: 70%;
    }
    .fileData {
      .fileName {
        width: 180px;
      }
    }
  }
}

@media (min-width: $breakpointSM) {
  .uploadFile {
    flex-direction: row;
    padding: 16px;
    margin-bottom: 10px;

    .uploadMessage {
      flex-basis: 50%;
      padding: 0;
      margin: 0;
      padding: 0 10px;
    }
  }

  .uploadFileInfo {
    justify-content: space-between;
  }
  .fileData {
    .fileName {
      width: 90%;
    }
  }
}
