@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.cellText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.contactsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  .notificationContact {
    @extend .cellText;

    color: $secondary500;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .showMoreBtn {
    @extend .cellText;
    color: $secondary500;
    cursor: pointer;
    text-decoration-line: underline;
  }
}
