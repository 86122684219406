@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.listingsTableWrapper {
  width: 100%;
}

.tableText {
  font-size: 12px;
  line-height: 16px;
}

.listingsTable {
  .listingPrice,
  .listingRooms {
    @extend .tableText;
    color: $primary900;
    font-weight: 400;
  }

  .editButton {
    @extend .tableText;
    font-weight: 500;
  }

  .loadingWrapper {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255, 0.3);
    backdrop-filter: blur(7px);
  }

  & :global .MuiDataGrid-columnHeader,
  & :global .MuiDataGrid-filler {
    background: $secondary100;
  }

  & :global .MuiDataGrid-columnHeaders {
    padding: 12px 0;
    background-color: $secondary100;
  }

  & :global .MuiDataGrid-row {
    border: 1px solid $grey150;
    border-top: none;
    max-height: unset !important;
    min-height: 100px;

    &.needUpdate {
      border-left: 1px solid $warning700;
    }
  }

  & :global .MuiDataGrid-cell {
    border: none;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 100px;

    &:focus-within {
      outline: none;
    }

    @media (min-width: $breakpointMD) and (max-width: 1100px) {
      padding: 16px;
    }
  }

  & :global .MuiDataGrid-columnHeader {
    padding: 0 24px;

    &:focus-within {
      outline: none;
    }
  }

  & :global .MuiDataGrid-columnHeaderTitle {
    color: $grey800;
    font-size: 12px;
    font-style: normal;
    white-space: normal;
    line-height: 20px;
  }

  & :global .MuiDataGrid-iconButtonContainer {
    visibility: visible !important;
    width: auto !important;
  }

  & :global .MuiDataGrid-sortIcon {
    opacity: inherit !important;
  }

  & :global .MuiDataGrid-cellContent {
    line-break: normal;
    white-space: normal;
  }

  & :global .MuiDataGrid-cellContent {
    color: $primary900;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
