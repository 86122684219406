@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.container {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  max-width: 1400px;

  .successMessageWrapper {
    position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;

    .successMessageCloseButton {
      color: $dark300;
      border: 1px solid $dark300;
      margin-left: 30px;
      border-radius: 3px;
    }
  }
}

@media (max-width: $breakpointMD) {
  .container {
    height: unset;
    overflow: unset;
  }
}

@media (min-width: $breakpointLG) {
  .container {
    box-shadow: 0px 20px 24px -4px rgba(69, 80, 100, 0.08),
    0px 8px 8px -4px rgba(69, 80, 100, 0.03);
  }
}
