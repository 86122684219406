@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.iconWrapper {
  display: flex;
  justify-content: center;
}

.errorTitle {
  margin-bottom: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $primaryDark;
}

.errorSubtitle {
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
  color: $error700;
  margin-bottom: 24px;
}

.ctaButton {
  color: $paper;
  background-color: $secondaryMain;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($color: $secondaryMain, $amount: 15);
  }
}
