@import "@/src/styles/variables.scss";
@import "@/src/styles/scss/theme.module.scss";

.imagePlaceholder {
  width: 100px;
  min-width: 100px;
  height: 60px;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: $secondary100;

  &.withLightBackground {
    background-color: $paper;
  }
}

.noImageText {
  color: $grey800;
  font-size: 8px;
  line-height: 120%;
  white-space: break-spaces;
  max-width: 80%;
}

