@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500p;
  line-height: 16px;
  border-radius: 0;
}

.infoModalTitleWrapper {
  display: flex;
  justify-content: center;

  .infoModalTitle {
    color: $primary900;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 6px 0 16px;
  }
}

.infoButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;

  &:hover {
    background: $secondary500;
  }
}

@media (min-width: $breakpointMD) {
  .infoModalTitleWrapper {
    .infoModalTitle {
      margin: 0 0 16px;
      max-width: 285px;
    }
  }
}
