@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.sectionsList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 20px;
  margin-top: 20px;
}
