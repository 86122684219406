@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.headingSection {
  padding: 20px;
  background-color: $secondary100;
  box-shadow: 0px 4px 8px -2px rgba(69, 80, 100, 0.08),
    0px 2px 4px -2px rgba(69, 80, 100, 0.04);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 -20px;

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

@media (min-width: $breakpointMD) {
  .headingSection {
    padding: 20px 40px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0;
    margin-right: -20px;

    .title {
      font-size: 20px;
      line-height: 32px;
      text-align: left;
    }
  }
}
