@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.paginationWrapper {
  position: absolute;
  bottom: 0px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: $paper;
  width: 100%;
  
  &.withTopBorder {
    border-top: 1px solid $grey300;
  }
}

@media (max-width: $breakpointMD) {
  .paginationWrapper {
    position: unset;
  }
}