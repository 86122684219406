@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.formSection {
  width: 100%;

  .formSectionTitle {
    color: $primary900;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }

  .formSectionSubTitle {
    color: $grey800;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 20px;

    @media (min-width: $breakpointMD) {
      min-height: 70px;
      margin-bottom: 10px;
    }
  }
}

.formItem {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .inputLabel {
    font-size: 12px;
    line-height: 16px;
    color: $grey750;
    text-transform: uppercase;

    &.required {
      &:after {
        content: "*";
        color: $error700;
        position: relative;
        top: 2px;
        left: 3px;
      }
    }
  }

  .formAutocomplete {
    border-radius: 1px;
    border: 1px solid $secondary300;
    
    &:global(.Mui-focused) {
      border: 1px solid $secondary500;
    }
  }

  &.noAutocompleteOptions {
    & :global .MuiAutocomplete-popper {
      &:has(:global(.MuiAutocomplete-noOptions)) {
        display: none;
      }
    }
  }

  .textField {
    & :global .MuiInputBase-root {
      background-color: $secondary100 !important;

      &:has(> input:focus) {
        background-color: $paper !important;
      }
    }

    & :global .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    input,
    textarea {
      background-color: $secondary100 !important;
      font-size: 12px !important;
      color: $dark300 !important;
      font-weight: 400;
      line-height: 16px;

      &:focus {
        background-color: $paper !important;
      }
    }

    input::placeholder {
      color: $grey800;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .inputProgress {
      color: $secondary500;
    }

    p {
      margin-left: 0 !important;
    }
  }
}

.collapseButton {
  width: 100%;
  height: 40px;
  padding: 14px 10px;
  background-color: $secondary250;
  color: $secondary500;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
