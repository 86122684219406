@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.descriptionInfo {
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  right: 0;

  &.isValid {
    color: $successDark;
    bottom: -20px;
  }

  &.isError {
    color: $error700;
    bottom: 0;
  }
}

@media (min-width: $breakpointMD) {
  .descriptionInfo {  
    &.isError {
      bottom: 21px;
    }
  }
}

@media (min-width: 1120px) {
  .descriptionInfo {  
    &.isError {
      bottom: 3px;
    }
  }
}
