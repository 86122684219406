@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.stepperWrapper {
  padding: 20px;
  display: flex;
}

@media (min-width: $breakpointLG) {
  .stepperWrapper {
    padding: 20px 30px;
  }
}
