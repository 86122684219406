@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.mainWrapper {
  position: relative;
  height: 100%;

  .inquiryTitleContainer {
    margin: 0 0 20px;
    padding: 0 40px;
  }

  .inquiryTitle {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    
  }

  .buttonCollapse {
    transition: all ease-in-out 0.3s;

    position: absolute;
    opacity: 1;
    z-index: 100;
    top: -15px;
    padding: 0;
    min-width: 30px;
    height: 30px;
    visibility: visible;

    &.buttonCollapseFaqOpen {
      opacity: 0;
      visibility: hidden;
    }
  }

  .buttonCollapseLeft {
    @extend .buttonCollapse;

    left: -20px;
  }

  .buttonCollapseRight {
    @extend .buttonCollapse;

    display: none;
  }

  .titleLine {
    display: flex;
    flex-direction: row;
  }

  .backButton {
    align-items: flex-start !important;
    margin: 4px 0px 0px 8px !important;
    padding: 0px !important;
    min-width: 40px;
  }
}

@media (max-width: 1200px) {
  .mainWrapper {
    .inquiryTitle {
      font-size: 32px;
      line-height: 35px;
    }

    .buttonCollapse {
      top: -5px;
    }

    .buttonCollapseLeft {
      left: -20px;
    }

    .buttonCollapseRight {
      display: block;
      right: -20px;
      top: -10px;
    }
  }
}

@media (max-width: $breakpointMD) {
  .mainWrapper {
    .buttonCollapseLeft,
    .buttonCollapseRight {
      display: none;
    }

    .inquiryTitleContainer {
      margin-bottom: 12px;
      margin-top: 0px;
      padding-right: 120px;
      padding-left: 0px;
    }

    .inquiryTitle {
      width: calc(100vw - 150px);
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis 
    }

    .inquirySubtitle {
      width: calc(100vw - 150px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis 
    }
  }
}
