@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.loaderWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  backdrop-filter: brightness(0.5);
  align-items: center;
  justify-content: center;
}
