@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.roomsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.roomActionButtons {
  display: flex;
  gap: 16px;
}

.actionButton {
  height: 40px;
  padding: 14px;
  background: $secondary250;
  width: 100%;
  color: $secondaryMain;
  transition: background-color 0.3s;

  &.disabled,
  &:disabled {
    opacity: 0.6;
    color: $secondaryMain;
  }

  &:hover {
    background-color: darken($secondary250, 10%);
  }
}

.roomItem {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 8px 12px 12px;
  gap: 16px;

  border-radius: 12px;
  background: $paper;

  box-shadow:
    0px 20px 24px -4px rgba(69, 80, 100, 0.08),
    0px 8px 8px -4px rgba(69, 80, 100, 0.03);

  .trashIconContainer {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.roomNameWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid $secondary50;
  padding: 16px 0;

  .roomName {
    color: $primary900;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .roomImage {
    width: 100px;
    max-width: 100px;
    height: 60px;
    object-fit: cover;
  }
}

.roomStatusWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .roomStatus {
    color: $grey750;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }

  .statusLabel {
    width: fit-content;
  }
}
