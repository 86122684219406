@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.selectedRoomDetails {
  width: 45%;
}

.modalTitle {
  color: $primary900;
  text-align: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
