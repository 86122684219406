@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.confirmBookingModal {
  & :global .MuiPaper-root {
    padding: 24px;
    width: 500px;
  }
}

.dialogWithExitBtnContainer {
  position: relative;
  z-index: 1;
}

.dialogWithExitExitBtnInner {
  position: absolute;
  top: -8px;
  right: -8px;
  color: $darkPaper;
  z-index: 999;
}

.modalTitle {
  color: $darkPaper;
  text-align: center;
  margin: 8px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.commonSubTitle {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;
  padding: 0 30px;
}

.modalSubtitle {
  @extend .commonSubTitle;

  color: $grey800;

  &.error {
    color: $error700;
  }
}

.maxSizeFileLimitedError {
  @extend .commonSubTitle;

  color: $error700;
}

.inputWrapper {
  margin-bottom: 13px;
}

.textFieldCommon {
  width: 100%;
  padding: 20px;
  color: $dark300;
  background-color: $secondary100;
  border-width: 1px;
  border-style: solid;
}

.textField {
  @extend .textFieldCommon;

  border-color: $grey400;
}

.textFieldError {
  @extend .textFieldCommon;

  border-color: $error700;
}

.textFieldLabel {
  margin-bottom: 8px;
  display: block;
  text-transform: uppercase;
  color: $grey750;
  font-weight: 500;

  &::after {
    content: " *";
    color: $errorDark;
  }
}

.error {
  color: $error700;
  margin-top: -5px;
  margin-bottom: 15px;
}

.confirmBookingButton {
  background-color: $secondary500;
  color: $paper;
  padding: 14px 10px;
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  transition: background-color 0.5s ease;

  &:disabled {
    color: $paper;
    background-color: rgba($secondary500, 0.2);
  }

  &:hover {
    opacity: 0.8;
    background-color: rgba($secondary500, 1);
  }
}

