@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.commonWrapperStyles {
  display: flex;
  width: 100%;
  height: 100vh;
}

.progressWrapper {
  @extend .commonWrapperStyles;

  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpointMD) {
  .commonWrapperStyles {
    height: 80vh;
  }
}

