@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.roomsList {
  width: 55%;
}

.actionButton {
  height: 40px;
  padding: 14px;
  background: $paper;
  color: $secondary500;

  &.disabled, &:disabled {
  color: $secondary500;

    opacity: 0.3;
  }
}

.roomItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 20px 24px;
  cursor: pointer;
  position: relative;

  &.selected {
    background: $secondary100;
  }

  .trashIconContainer {
    position: absolute;
    top: 10px;
    left: 0;
  }

  .roomImage {
    width: 100px;
    min-width: 100px;
    height: 60px;
    object-fit: cover;
  }
}

.roomNameWrapper {
  display: flex;
  width: 50%;
  align-items: center;
  gap: 12px;

  .roomName {
    color: $primary900;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
