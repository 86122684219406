@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.leftContainer {
  position: relative;
  height: calc(100vh - 130px);

  .inquriesListTitle {
    font-size: 16px;
    padding: 15px 20px 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: $darkPaper;
    margin-bottom: 10px;

    .inquriesListAmount {
      color: $grey400;
    }
  }

  .filters {
    padding: 0 7px 20px;
    border-bottom: 1px solid $grey300;
    position: relative;
    box-shadow: 0 1px 1px 0 rgba($primary900,.25);

    .filtersWrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  .buttonCollapse {
    position: fixed;
    opacity: 1;
    z-index: 1;
    top: 100px;
    left: 19.5%;
    padding: 0;
    min-width: 30px;
    height: 30px;
  }

  .counterWrapper {
    padding: 8px 16px;
  }
}

.inquriesContainer {
  padding: 0;
  //192px = the height of filter + pagination
  height: calc(100% - 192px); 
  overflow-y: scroll;

  &.withNeedsUpdateInquiriesCounter {
  //324px = the height of filter + pagination + needs update counter
    height: calc(100% - 324px); 
  }
}

.filterItem {
  padding-bottom: 20px;
  border-bottom: 1px solid $grey300;
  margin: 0 10px;
  padding: 20px 0;
  display: block;

  &:nth-of-type(3) {
    border-width: 0px;
  }

  &:hover {
    background: $paper !important;
  }

  .filterTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: $grey500;
    margin-bottom: 10px;
  }
}

@media (max-width: $breakpointMD) {
  .leftContainer {
    margin: 0px 20px;
    height: unset;

    .inquriesListTitle {
      font-size: 14px;
      padding: 15px 15px 20px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .filters {
      padding: 0 20px 8px;
    }

    .inquiryItem {
      padding: 15px 10px;

      .commonText {
        font-size: 10px;
        line-height: 14px;
      }

      .buttonsList {
        max-width: 60%;
      }
    }
  }

  .inquriesContainer {
    padding: 0px;
    height: unset;
  }
}

