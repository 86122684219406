@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.roomsForm {
  padding: 20px 0;

  .formSection {
    .formSectionTitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: $dark300;
      margin-bottom: 6px;
    }
  }

  .formItemsWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .formItem {
    margin-bottom: 20px;
    position: relative;

    &.twoInRow {
        width: calc(100% / 2);
      }

    & :global .MuiSelect-select {
      color: $dark300;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      height: 1.4375em;
      background-color: $secondary100 !important;
    }

    .inputLabel {
      font-size: 12px;
      line-height: 16px;
      color: $grey750;
      text-transform: uppercase;
      margin-bottom: 10px;

      &.required {
        &:after {
          content: "*";
          color: $error700;
          position: relative;
          top: 2px;
          left: 3px;
        }
      }
    }

    .textField {
      & :global .MuiInputBase-root {
        background-color: $secondary100 !important;
      }

      input,
      textarea {
        background-color: $secondary100 !important;
        font-size: 12px !important;
        color: $grey800 !important;
      }

      p {
        margin-left: 0 !important;
      }

      &.roomDescription {
        p {
          padding-right: 40px;
        }
      }
    }

    .addressIcon {
      position: absolute;
      top: 17px;
      left: 11px;
    }

    .addressInputLoaderWrapper {
      position: absolute;
      top: 17px;
      right: 40px;
      padding: 0;
    }

    .hiddenInputWrapper {
      width: 100%;
      border: 1px solid $grey400;
      background-color: $secondary100;
      font-size: 12px;
      color: $grey800;
      padding: 9px 20px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .hiddenInput {
        display: none;
      }

      .hiddenInputPlaceholderText {
        padding: 8px 10px;
        color: $secondary500;
        width: fit-content;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        border: 0.5px dashed $secondary500;
      }

      &:focus,
      &:hover {
        border-color: $grey900;
      }

      &:focus {
        border-width: 2px;
      }
    }
  }

  .showPricingControl {
    margin-bottom: 20px;
    align-items: flex-start;

    .switchPricing {
      & :global .MuiSwitch-switchBase.Mui-checked {
        color: $secondary500;
        &:hover {
          background-color: alpha($secondary500);
        }
      }

      & :global .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: $secondary500;
      }
    }
  }

  .showPricingControlName {
    color: $dark300;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    display: block;
  }

  .showPricingControlText {
    color: $grey750;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .roomsSaveButton {
    color: $paper;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding: 12px 8px;
    background-color: $secondaryMain;
    width: 100%;

    &.isDisabled {
      background-color: rgba($secondaryMain, 0.2);
    }
  }

  .formActionButtons {
    display: flex;
    gap: 20px;
    .actionButton {
      color: $paper;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      padding: 12px 8px;
      background-color: $secondaryMain;
      width: 100%;

      &.isDisabled {
        background-color: rgba($secondaryMain, 0.2);
      }
    }
  }
}

@media (min-width: $breakpointMD) {
  .roomsForm {
    padding: 0;
    .formItemsWrapper {
      flex-direction: row;
      justify-content: space-around;
      margin: 0 -10px;

      .formItem {
        padding: 0 10px;
        width: calc(100% / 3);
        &.twoInRow {
          width: calc(100% / 2);
        }
      }
    }

    .roomsSaveButton {
      width: auto;
    }
  }
}
