@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.categoriesList {
  margin: 24px 0 32px;
  padding: 0 !important;
  border: 1px solid $secondary100;

  .categoriesListItem {
    color: $primary900;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding: 12px 20px;
    border-bottom: 2px solid $secondary100;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &.isActive {
      background-color: $grey150;
    }

    .categoryLabel {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
      cursor: pointer;
    }

    .categoryRadioButton {
      span {
        display: none;
      }
    }

    .fakeRadioButton {
      width: 15px;
      height: 15px;
      border-radius: 20px;
      border: 1px solid $grey750;
    }
  }
}
