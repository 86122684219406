@import "@/src/styles/variables.scss";
@import "@/src/styles/scss/theme.module.scss";

.headerSticky {
  position: sticky;
  top: 0;
  z-index: var(--zIndexNavigationBarDesktop);
}

.header {
  height: 80px;
  z-index: 1000;
  padding: 0 0px 0px 20px;
  background: $primaryDark;
  display: flex;
  justify-content: space-between;
  gap: 0px;
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  height: 100%;
  overflow-x: scroll;
}

@media (max-width: 1300px) {
  .headerLeft {
    gap: 40px;
  }
}

.headerLeft::-webkit-scrollbar {
  display: none;
}

.headerLeftDesktopLogo {
  position: relative;
}

.headerLeftMobileLogo {
  display: none;
}

.logoLink {
  text-decoration: none;
}

.headerLogoLabel {
  color: $paper;
  margin-left: 70px;
}

.headerLogoLabel p {
  margin: -1px 0px 0px;
  padding: 0px;
}

.headerNav,
.headerNavLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  height: 100%;
}

.headerNavLinks a {
  color: $paper;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.headerNavLinks a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $paper;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.headerNavLinks a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.headerOptsDrawerBtn {
  position: relative;
  z-index: 9999;
  width: 80px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.headerOptsDrawerBtnInner {
  cursor: pointer;
  border-radius: var(--borderRadiusSmall);
  padding: 9px 7px;
}

.drawerHeaderExitBtn {
  display: none;
}

@media (max-width: $breakpointMD) {
  .header {
    padding: 0px 0px 0px 18px;
    max-width: 100%;
    overflow: hidden;
  }

  .headerLeft {
    width: calc(100% - 61px);
    gap: 12px;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }

  .headerLeft:global(.focused) {
    width: calc(100% - 18px);
  }

  .headerLeftDesktopLogo {
    display: none;
  }

  .headerLeftMobileLogo {
    display: block;
    margin-top: 6px;
  }

  .headerNavLinks {
    display: none;
  }

  .drawerHeaderExitBtn {
    position: fixed;
    right: 0px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.drawerScrollbar {
  position: relative;
  z-index: 9999;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $grey400;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

.textDecoration {
  text-decoration: none;
}

.drawerWrapper {
  padding: 20px 24px;

  .drawerDivider {
    border-color: $grey500;
  }

  .title {
    text-transform: uppercase;
    color: $grey500;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0;
  }

  .link {
    @extend .textDecoration;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    display: block;
    color: $paper;
  }

  .accountLink {
    @extend .textDecoration;
  }

  .accountLink button {
    box-shadow: none !important;
  }

  .accountLinkContent {
    @extend .link;

    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .arrowOffsetRight {
    margin-right: 5px;
  }

  .pagesList {
    list-style: none;
    padding-left: 0;
  }
}

.accountWrapper {
  margin-top: 20px;
}

@media (max-width: $breakpointMD) {
  .drawerWrapper {
    margin-bottom: 120px;
  }
}

.linkButton {
  text-decoration: none;
}
