@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.hiddenInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.uploadTextButton {
  color: $secondary500;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
}
