@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.detailsSaveButton {
  color: $paper;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 12px 8px;
  background-color: $secondaryMain;
  width: 100%;

  &:hover {
    background-color: rgba($secondaryMain, 0.9);
  }
  
  &:disabled {
    background-color: $secondaryMain;
    color: $paper;
  }

  &.isDisabled {
    background-color: rgba($secondaryMain, 0.2);
    color: $paper;
  }
}

@media (min-width: $breakpointMD) {
  .detailsSaveButton {
    width: auto;
    &.fullWidth {
      width: 100%;
    }
  }
}
