@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.invoiceTabContent {
  padding: 20px 40px;
}

@media (max-width: $breakpointMD) {
  .invoiceTabContent {
    padding: 20px;
  }
}

