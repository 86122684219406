@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.label {
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.published,
.draft,
.pending,
.unpublished {
  @extend .label;
}

.pending {
  color: $secondary500;
  background-color: $secondary250;
}

.draft {
  color: $warning700;
  background-color: $warning100;
}

.unpublished {
  background-color: $grey150;
  color: $grey800;
}

.published {
  background-color: $success50;
  color: $success300;
}

.defaultStatus {
  color: $primary900;
  font-size: 12px;
  line-height: 16px;
}
