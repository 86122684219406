@import "@/src/styles/scss/theme.module.scss";

.cancelOption {
  margin: 0;
  & :global .MuiFormControlLabel-label {
    color: $dark300;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  & :global .MuiRadio-root {
    color: $grey750;
    padding-left: 0;
  }

  & :global .MuiRadio-root.Mui-checked {
    color: $secondary500;
  }

  & :global .MuiSvgIcon-root {
    font-size: 16px;
  }
}
