@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.pricingForm {
  padding: 20px 0;
  width: 100%;

  .formSection {
    .formSectionTitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: $dark300;
      margin-bottom: 6px;
    }
  }

  .pricingHelperText {
    color: $dark300;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .formItemsWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .formItem {
    margin-bottom: 20px;
    position: relative;

    &.twoInRow {
      width: calc(100% / 2);
    }

    & :global .MuiSelect-select {
      color: $dark300;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      height: 1.4375em;
      background-color: $secondary100 !important;
    }

    .inputLabel {
      font-size: 12px;
      line-height: 16px;
      color: $grey750;
      text-transform: uppercase;
      margin-bottom: 10px;

      &.required {
        &:after {
          content: "*";
          color: $error700;
          position: relative;
          top: 2px;
          left: 3px;
        }
      }
    }

    .textField {
      & :global .MuiInputBase-root {
        background-color: $secondary100 !important;
      }

      input,
      textarea {
        background-color: $secondary100 !important;
        font-size: 12px !important;
        color: $grey800 !important;
      }

      p {
        margin-left: 0 !important;
      }

      &.pricingDescription {
        p {
          padding-right: 40px;
        }
      }
    }
  }

  .showPricingControl {
    margin-bottom: 20px;
    align-items: flex-start;

    .switchPricing {
      & :global .MuiSwitch-switchBase.Mui-checked {
        color: $secondary500;
        &:hover {
          background-color: alpha($secondary500);
        }
      }

      & :global .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: $secondary500;
      }
    }
  }

  .showPricingControlName {
    color: $dark300;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    display: block;
  }

  .showPricingControlText {
    color: $grey750;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

@media (min-width: $breakpointMD) {
  .pricingForm {
    padding: 0;
    width: 45%;

    .formItemsWrapper {
      flex-direction: row;
      justify-content: space-around;
      margin: 0 -10px;

      .formItem {
        padding: 0 10px;
        width: calc(100% / 3);
        &.twoInRow {
          width: calc(100% / 2);
        }
      }
    }
  }
}
