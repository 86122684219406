@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.sortingWrapper {
  margin: 0 10px;
  padding: 15px 0 0;
  display: block;

  .sortingTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: $grey400;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .sortingOptionsList {
    list-style: none;
    padding: 0 20px;
    margin-top: 15px;

    .sortingListItem {
      transition: all 0.3s ease-in;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      padding: 15px 10px;
      color: $primary900;

      &:hover, &.active {
        background-color: $secondary100;
      }

      .sortingListItemContent {
        padding: 0 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}


@media (max-width: $breakpointMD) {
  .sortingOptionsList {
    list-style: none;
    padding: 0;
  }
}