@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.fileUploadWrapper {
  margin-bottom: 12px;
}

.fileLabel {
  font-size: 12px;
  line-height: 16px;
  color: $grey750;
  text-transform: uppercase;
  margin-bottom: 10px;
}
