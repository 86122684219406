@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
  border-radius: 0;
}

.uploadButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;
  margin-bottom: 20px;

  .addButtonIcon {
    margin-left: 4px;
  }
}

.roomsWrapper {
  display: flex;
  gap: 40px;
}

.roomsList {
  width: 55%;
}

.deactivateButton {
  height: 40px;
  padding: 14px;
  background: $paper;
  color: $secondaryMain;

  &.disabled {
    color: rgba($secondaryMain, 0.2);
  }
}

.roomItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 20px 24px;

  &.selected {
    background: $secondary100;
  }
}

.roomNameWrapper {
  display: flex;
  width: 50%;
  align-items: center;
  gap: 12px;

  .roomName {
    color: $primary900;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.selectedRoomDetails {
  width: 45%;
}

@media (min-width: $breakpointMD) {
  .addButtonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .uploadButton {
    width: unset;
  }
}
