@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

@media (min-width: $breakpointMD) {
  .categoryItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -0.5px;
      left: 90%;
      height: 1px;
      width: 20%;
      background-color: $grey750;
    }

    &:first-child {
      padding-left: 0;
      justify-content: flex-start;

      &:after {
        left: 85%;
      }
    }

    &:last-child {
      padding-left: 20px;
      
      &:after {
        display: none;
      }
    }

    &.completed {
      &:after {
        background-color: $success300;
      }
    }

    .iconWrapper {
      margin-right: 6px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $grey150;
      display: flex;
      align-items: center;
      justify-content: center;

      &.completed {
        background-color: $success50;
      }
    }

    .stepTextWrapper {
      .stepNumber {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: $grey750;

        &.completed {
          color: $success100;
        }
      }

      .stepCategory {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: $grey800;
        text-transform: capitalize;

        &.completed {
          color: $success300;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .categoryItem {
    .iconWrapper {
      margin-right: 8px;
      width: 40px;
      height: 40px;
    }

    &:after {
      width: 25%;
      left: 85%;
    }

    &:first-child {
      &:after {
        left: 80%;
      }
    }
  }
}

@media (min-width: $breakpointLG) {
  .categoryItem {
    .stepTextWrapper {
      .stepNumber {
        font-size: 12px;
        line-height: 16px;
      }
      .stepCategory {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
