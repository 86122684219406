@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.photoWrapper {
    position: relative;
    height: 70px;
    transition: 0.3s;
    color: $paper;

    &.selected {
      box-shadow: 0px 4px 20px 0px rgba(32, 92, 223, 0.2);
    }

    .photo {
      width: 100px;
      height: 70px;
      object-fit: cover;

      &.isLoading {
        filter: brightness(50%);
      }
    }

    .imageLoaderWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      color: $paper;
    }

    .trashIconContainer {
      visibility: hidden;
      position: absolute;
      cursor: pointer;
      bottom: 8px;
      right: 8px;
      height: 24px;
      width: 24px;
    }
    .checkboxWrapper {
      position: absolute;
      top: 8px;
      left: 12px;

      input[type="checkbox"] {
        margin: 0;
        transform: scale(1.5);
      }
    }

    &:hover {
      .trashIconContainer {
        visibility: visible;
      }
    }

    &:active {
      .trashIconContainer {
        visibility: visible;
      }
    }
  }

  @media (max-width: $breakpointMD) {
    .photoWrapper {
        position: relative;
        height: 90px;
        transition: 0.3s;
        color: white;
        width: calc((100% / 4) - 6px);
        .photo {
            width: 100%;
            height: 100%;
        }
    }
    
  }

  @media (max-width: $breakpointSM) {
    .photoWrapper {
        position: relative;
        height: 70px;
        transition: 0.3s;
        color: white;
        width: calc((100% / 3) - 6px);
        .photo {
            width: 100%;
        }
    }
    
  }
