@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.counterWrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  background: $grey150;

  .counterInner {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .counterValue {
    color: $grey800;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .counterInfo {
    color: $grey800;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }
  
  .questionIcon {
    margin-left: 8px;
  }

  .counterCtaContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .counterCtaWrapper {
    padding: 14px 10px;
    background: $secondary150;
    width: fit-content;
    cursor: pointer;
  }

  .counterCta {
    color: $secondary500;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-decoration: none;
  }
}

.updateStatus {
  & :global .MuiSvgIcon-root {
    font-size: 24px;
    color: $grey750;
  }

  & :global .MuiFormControlLabel-label {
    color: $grey800;
    font-size: 12px;
    line-height: 16px;
  }
}


@media (max-width: $breakpointMD) {
  .counterWrapper {
    flex-direction: column;
    gap: 16px;
  }
}