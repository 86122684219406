@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.vendorsForm {
  padding: 20px 0;

  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;

    @media (min-width: $breakpointMD) {
      flex-direction: row;
      gap: 50px;
    }
  }

  @media (min-width: $breakpointMD) {
    width: 100%;
  }
}
