@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.bannerContainer {
  border-radius: 2px;
  background-color: $secondary100;
  display: flex;
  flex-direction: column;
  margin: 0 40px 10px;
  padding: 16px;
}

.bannerTitleWrapper {
  display: flex;
  align-items: center;
}

.bannerTitle {
  color: $dark300;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding-left: 5px;
}

.bannerReason {
  color: $grey800;
  font-size: 12px;
  line-height: 16px;
  padding-left: 25px;
}

.bannerCTA {
  color: $dark300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  width: 123px;
  margin-top: 12px;
  margin-left: 18px;
  font-size: 12px;
}
