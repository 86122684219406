@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.attachments {
    width: 100%;
  }
  
  .attachmentWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 0;
  }
  
  .attachmentInfo {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .attachmentInfoContainer {
    .attachmentName {
      color: $primary900;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  
    .attachmentFileSize {
      color: $grey800;
      font-size: 12px;
      line-height: 16px;
    }
  }
  
  .attachmentActions {
    display: flex;
    align-items: center;
  
    .action {
      display: flex;
      min-width: 32px;
      padding: 0;
    }
  }
  
  .divider {
    margin: 8px 0;
  }
  