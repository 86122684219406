@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.loglist {
  list-style: none;
  padding-left: 0;

  .listItem {
    display: flex;
    margin-bottom: 20px;
  }
}

