@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.FAQContainerWrapper {
  padding: 20px;
  position: relative;

  .FAQContainerTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: $secondary500;
    margin-bottom: 20px;
  }

  .categoriesList {
    padding: 10px 0;

    .categoryItemWrapper {
      list-style: none;
      margin-bottom: 30px;
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  margin-left: -20px;
  left: 50%;
}

@media (max-width: $breakpointMD) {
  .FAQContainerWrapper {
    overflow-y: auto;
  }
}
