@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.inquiryManagerList {
  padding: 0 20px;

  .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .linkButton {
    @extend .button;

    background-color: $secondaryLight;
  }
}
