@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.listingManagementContainer {
  padding: 0 20px;
  max-width: 1440px;
  margin: auto;
}

@media (min-width: $breakpointMD) {
  .listingManagementContainer {
    display: flex;
    flex-wrap: nowrap;

    .leftSidebarWrapper {
      width: 20%;
    }

    .mainSectionWrapper {
      width: 80%;
    }
  }
}
