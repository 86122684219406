@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.cardHeading {
  color: $grey750;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.divider {
  border-color: $secondary50;
  width: 100%;
}

.listingPrice {
  color: $grey800;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.cardButtons {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  .buttonWrapper {
    width: 100%;
  }

  .listingActionButton {
    width: 100%;
    padding: 14px 10px;
    background-color: $secondary250;
    color: $secondary500;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .disabled {
    opacity: 50%;
  }
}
